var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card on-modal"},[_c('div',{staticClass:"card-content"},[_c('header',{staticClass:"is-flex is-justify-content-space-between is-align-items-center mb-2"},[_c('h2',{staticClass:"is-size-6 has-text-primary has-text-weight-bold mr-3"},[_vm._v("Form "+_vm._s(_vm.$t('farm.pond')))]),_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close-thick"}})],1)]),_c('ValidationObserver',{ref:"form"},[_c('form',{staticClass:"pt-4",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.block_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('farm.block_name'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"text","id":"block_name","value":((_vm.$t('farm.block')) + " " + (_vm.selectedBlock.block_name)),"placeholder":("Input " + (_vm.$t('farm.block_name'))),"disabled":""}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.pond_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('farm.pond_name'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"text","id":"pond_name","placeholder":("Input " + (_vm.$t('farm.pond_name')))},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.area'),"rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":((_vm.$t('farm.area')) + " (m2)"),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","id":"area","step":"any","placeholder":("Input " + (_vm.$t('farm.area')))},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", _vm._n($$v))},expression:"form.area"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":("Total " + (_vm.$t('feed_tray.feed_tray'))),"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":("Total " + (_vm.$t('feed_tray.feed_tray'))),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","id":"anco","placeholder":("Input Total " + (_vm.$t('feed_tray.feed_tray')))},model:{value:(_vm.form.anco),callback:function ($$v) {_vm.$set(_vm.form, "anco", _vm._n($$v))},expression:"form.anco"}})],1)]}}])}),_c('footer',{staticClass:"has-text-right"},[_c('b-button',{attrs:{"type":"is-secondary is-light"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('b-button',{staticClass:"ml-2",attrs:{"native-type":"submit","type":"is-success"}},[_vm._v(_vm._s(_vm.$t('save')))])],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }