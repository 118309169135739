<template>
  <div class="card on-modal">
    <div class="card-content">
      <header class="is-flex is-justify-content-space-between is-align-items-center mb-2">
        <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">Form {{ $t('farm.pond') }}</h2>
        <div @click="$emit('close')">
          <b-icon class="cursor-pointer" icon="close-thick"/>
        </div>
      </header>
      <ValidationObserver ref="form">
        <form class="pt-4" autocomplete="off" @submit.prevent="handleSubmit">
          <ValidationProvider :name="$t('farm.block_name')" rules="required" v-slot="{ errors }">
            <b-field :label="$t('farm.block_name')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
              <b-input type="text" id="block_name" :value="`${$t('farm.block')} ${selectedBlock.block_name}`" :placeholder="`Input ${$t('farm.block_name')}`" disabled></b-input>
            </b-field>
          </ValidationProvider>
          <ValidationProvider :name="$t('farm.pond_name')" rules="required" v-slot="{ errors }">
            <b-field :label="$t('farm.pond_name')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
              <b-input type="text" id="pond_name" v-model="form.name" :placeholder="`Input ${$t('farm.pond_name')}`"></b-input>
            </b-field>
          </ValidationProvider>
          <ValidationProvider :name="$t('farm.area')" rules="required|double" v-slot="{ errors }">
            <b-field :label="`${$t('farm.area')} (m2)`" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
              <b-input type="number" id="area" step="any" v-model.number="form.area" :placeholder="`Input ${$t('farm.area')}`"></b-input>
            </b-field>
          </ValidationProvider>
          <ValidationProvider :name="`Total ${$t('feed_tray.feed_tray')}`" rules="required|numeric" v-slot="{ errors }">
            <b-field :label="`Total ${$t('feed_tray.feed_tray')}`" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
              <b-input type="number" id="anco" v-model.number="form.anco" :placeholder="`Input Total ${$t('feed_tray.feed_tray')}`"></b-input>
            </b-field>
          </ValidationProvider>
          <footer class="has-text-right">
            <b-button type="is-secondary is-light" @click="$emit('close')">{{ $t('cancel') }}</b-button>
            <b-button native-type="submit" type="is-success" class="ml-2">{{ $t('save') }}</b-button>
          </footer>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>

export default {
  name: "FormPond",
  props: {
    defaultValue: {
      default: null
    },
    selectedBlock: Object,
  },
  data: () => {
    return {
      form: {}
    }
  },
  watch: {
    defaultValue: {
      deep: true,
      handler() {
        this.resetFarmValue()
      }
    }
  },
  created() {
    if (this.defaultValue) {
      this.resetFarmValue()
    }
  },
  methods: {
    resetFarmValue() {
      this.form = this.defaultValue ? this._.cloneDeep(this.defaultValue) : {}
    },

    async handleSubmit() {
      this.$refs['form'].validate().then(async success => {
        if (success) {
          this.$loading()

          let data = {
            farm_id: parseInt(this.$route.params.farm_id),
            name: this.form.name,
            status: this.form.status || 0,
            anco: this.form.anco,
            area: this.form.area,
            block_name: this.selectedBlock.block_name,
          }

          let res = {}

          if (this.form.pond_id) {
            data.pond_id = this.form.pond_id
            res = await this.$store.dispatch('farm/updatePond', data)
          } else {
            res = await this.$store.dispatch('farm/addPond', data)
          }

          if (!res.error) {
            await this.$store.dispatch('farm/getBlock')
            await this.$store.dispatch('farm/getPond')
            this.$emit('close')
          }

          this.$loading(false)
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.card {
  &.on-modal {
    width: 650px !important;
    max-width: 100%;
  }
}
</style>
